import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaMicrophone, FaSyncAlt } from 'react-icons/fa';
import { RiSpeakFill } from 'react-icons/ri';
import config from './config';
import Room from './Room'; // Import Room component
import './Help.css';
import { FaArrowLeft } from 'react-icons/fa';
import moment from 'moment-timezone';

// import Record from './Record'; // Import Record component

function Help() {
    const [messages, setMessages] = useState([
        // { role: 'assistant', content: '歡迎光臨鴻翊國際！請按下麥克風，告訴我您的姓名與公司。我將協助您連繫您要找的人' },
        { role: 'assistant', content: '歡迎光臨鴻翊國際！請按快捷鍵, 或按下麥克風，告訴我您的姓名與公司。我將協助您連繫您要找的人' },
    ]);

    const badgeDepartments = ['值班人員(快遞收發)', '倉庫(收取貨物)', '人力資源(面試)']; // Departments that need badges


    const [input, setInput] = useState('');
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [roomID, setRoomID] = useState(null);
    const [loading, setLoading] = useState(false); // State to track loading status
    const [responseData] = useState(null); // State to store the AI response data
    const chatBoxRef = useRef(null);
    const audioRef = useRef(null);
    const blipSound = useMemo(() => new Audio('/blip.mp3'), []);
    const doneSound = useMemo(() => new Audio('/done.mp3'), []);
    const stopSound = useMemo(() => new Audio('/stop.mp3'), []);
    const ringSound = useMemo(() => new Audio('/ring.mp3'), []);
    const mouseClickSound = useMemo(() => new Audio('/mouse-click.mp3'), []);

    const cameraStreamRef1 = useRef(null);
    const cameraStreamRef2 = useRef(null);
    const [showRecordModal, setShowRecordModal] = useState(false);
    const [showPeopleOptionsModal, setShowPeopleOptionsModal] = useState(false);
    const [transcription, setTranscription] = useState('');
    const [ttsStatus, setTtsStatus] = useState('silence'); // State to track TTS status
    const [showInputSection] = useState(false); // Change to 'true' if you want it visible by default
    const [isMicDisabled, setIsMicDisabled] = useState(false);
    const [showStartModal, setShowStartModal] = useState(true); // State to control the visibility of the start modal
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // State to manage loading effect

    const [showCallModal, setShowCallModal] = useState(false); // Modal state
    const [timer, setTimer] = useState(360); // 5 minutes in seconds (300 seconds)

    // State for idle timer
    const [idleTime, setIdleTime] = useState(0);

    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const sourceRef = useRef(null);
    const [microphoneAccessError, setMicrophoneAccessError] = useState(false);
    const [isTranscriptionSent, setIsTranscriptionSent] = useState(false);
    const [confirmedUserName, setConfirmedUserName] = useState(''); // New state to store the confirmed user name
    const [selectedDepartment, setSelectedDepartment] = useState(null); // Track the selected department

    const [silenceTimer, setSilenceTimer] = useState(null);
    const silenceTimeout = 5000; // Duration of silence in ms before considering as "no voice input"
    const silenceThreshold = 5; // Amplitude level threshold to consider as silence (adjust as needed)

    const [taiwanTime, setTaiwanTime] = useState(moment.tz('Asia/Taipei').format('HH:mm:ss'));

    const [isOfficeOpen, setIsOfficeOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState('');

    // Add these states to the top of your component
    const [officeContacts, setOfficeContacts] = useState([]);

    const fetchAllContacts = async () => {
        const allContacts = [];
        let currentPage = 1;
        const pageSize = 100; // Adjust this based on the API's limit per page

        const fetchPage = async (page) => {
            try {
                const response = await axios.get(
                    `${config.nocodbApiUrl}?page=${page}&limit=${pageSize}`, // Add page and limit to URL
                    {
                        headers: {
                            'xc-token': config.nocodbApiToken, // Use the nocodbApiToken from config
                        },
                    }
                );

                // console.log('API Response:', response.data.list); // Log the response to inspect the structure
                // Map through the response to ensure department_representative is converted to boolean
                const modifiedContacts = response.data.list.map((contact) => {
                    const { CreatedAt, UpdatedAt, Id, ...rest } = contact; // Remove unwanted fields
                    return {
                        ...rest,
                        department_representative: contact.department_representative === 'true' || contact.department_representative === true
                    };
                });

                // Append the current page data to allContacts
                allContacts.push(...modifiedContacts);

                // Check if there are more pages based on the actual structure of your API response
                if (response.data.meta && response.data.meta.page < response.data.meta.totalPages) {
                    // Fetch the next page recursively
                    await fetchPage(page + 1);
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        await fetchPage(currentPage); // Start fetching from page 1

        // Set the office contacts data once all pages are fetched
        setOfficeContacts(allContacts);

        console.log(allContacts)
        setLoading(false); // Turn off loading after fetching all data
    };

    // Call this function to fetch all contacts
    useEffect(() => {
        setLoading(true); // Set loading to true while data is being fetched
        fetchAllContacts();
    }, []);




    // Update Taiwan time every second and check office hours
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment.tz('Asia/Taipei');
            setTaiwanTime(currentTime.format('HH:mm:ss'));

            // Define office hours (Monday to Friday, 9:00 AM - 5:30 PM)
            const openingHour = moment.tz('Asia/Taipei').set({ hour: 9, minute: 0 });
            // const closingHour = moment.tz('Asia/Taipei').set({ hour: 9, minute: 10 });
            const closingHour = moment.tz('Asia/Taipei').set({ hour: 17, minute: 30 });

            // Check if the current time is within office hours and it's a weekday (1 = Monday, 5 = Friday)
            const isWithinOfficeHours = currentTime.isBetween(openingHour, closingHour) && currentTime.day() >= 1 && currentTime.day() <= 5;

            if (isWithinOfficeHours) {
                setIsOfficeOpen(true);
                setOpenMessage('目前AI助理服務中');
            } else {
                setIsOfficeOpen(false);
                setOpenMessage('目前AI助理服務暫停服務<br />開放時間：週一至週五，9:00 至 17:30');
                ;
            }
        }, 1000); // Update every second
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    // Update Taiwan time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setTaiwanTime(moment.tz('Asia/Taipei').format('HH:mm:ss'));
        }, 1000); // Update every second
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);


    const handleDepartmentClick = (department) => {
        setSelectedDepartment(department);
    };


    const handleReturnToMain = () => {
        setSelectedDepartment(null);
    };


    const [userProfile, setUserProfile] = useState({
        user_name: '',
        user_company: ''
    });

    // Tooltip styles
    const tooltipStyles1 = {
        position: 'absolute',
        top: '0px', // Position above the element
        left: '0%',
        padding: '12px 20px',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#fff',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #ff6f61, #d45079)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        // animation: 'flash 1.5s infinite', // Flashing animation
        animationTimingFunction: 'ease-in-out',
        transform: 'translate(-50%, -100%)', // Center above the target
    };

    // Arrow styles for the first tooltip
    const arrowStyles1 = {
        position: 'absolute',
        top: '100%', // Position the arrow below the tooltip
        left: '90%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #ff6f61',
        filter: 'drop-shadow(0 0 8px rgba(255, 111, 97, 0.8))', // Glow effect
    };



    const highlightStyles = {
        position: 'absolute',
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        opacity: 1,
    };


    // Function to handle user activity and reset idle timer
    const resetIdleTimer = useCallback(() => {
        setIdleTime(0); // Reset the idle timer
    }, []);



    const visualize = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');

        // Set up a larger canvas size
        canvas.width = 600; // Adjust as needed
        canvas.height = 400; // Adjust as needed

        const renderFrame = () => {
            if (!analyserRef.current || !dataArrayRef.current) return;

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            // Clear the canvas
            canvasContext.fillStyle = 'rgba(255, 255, 255, 0.1)'; // Background color
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            // Set styles for the waveform
            canvasContext.lineWidth = 3; // Thickness of the waveform line
            canvasContext.strokeStyle = 'rgba(0, 200, 100, 0.8)'; // Wave color

            canvasContext.beginPath();

            // Calculate slice width to spread the waveform across the canvas
            const sliceWidth = (canvas.width * 1.0) / analyserRef.current.frequencyBinCount;
            let x = 0;

            // Draw each point of the waveform
            for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
                const v = dataArrayRef.current[i] / 128.0;

                // Scale the amplitude to make the wave larger and center it
                const y = (v * canvas.height) / 2; // Scale amplitude
                const centeredY = y;  // Center the waveform at 50% height

                if (i === 0) {
                    canvasContext.moveTo(x, centeredY);
                } else {
                    canvasContext.lineTo(x, centeredY);
                }

                x += sliceWidth;
            }

            // Close the path and draw the wave
            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();

            // Continuously call renderFrame to animate the waveform
            requestAnimationFrame(renderFrame);
        };

        renderFrame();
    };





    const sendToOpenAI = useCallback(async (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.webm');
        formData.append('model', 'whisper-1');
        // formData.append('language', 'zh-TW');

        try {
            const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                headers: {
                    'Authorization': `Bearer ${config.openAiApiKey}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const transcription = response.data.text;
            onTranscription(transcription);
        } catch (error) {
            console.error('Error sending audio to OpenAI:', error);
        }
    }, []);

    const onTranscription = (transcription) => {
        setTranscription(transcription);
        // Optionally, handle the transcription further if needed
    };


    const onClose = () => {
        setMicrophoneAccessError(false);
    };



    // Function to start microphone and recording
    const startMicrophoneSetup = async () => {
        try {
            // Call handleStop to ensure all previous streams and processes are stopped
            handleStop();

            // Create a new AudioContext if not already created
            if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                console.log('AudioContext created successfully @ startMicrophoneSetup.');
            }

            // Ensure the AudioContext is in a running state
            if (audioContextRef.current.state === 'suspended') {
                await audioContextRef.current.resume();
                console.log('AudioContext resumed.');
            }

            // Request microphone access and start recording
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log('Microphone access granted.');
            microphoneRef.current = stream;

            sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
            console.log('MediaStreamSource created successfully.');
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 2048;
            sourceRef.current.connect(analyserRef.current);

            dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
            visualize();

            const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            mediaRecorderRef.current = mediaRecorder;
            console.log('MediaRecorder initialized successfully.');
            mediaRecorder.start();
            console.log('MediaRecorder started.');

            mediaRecorder.ondataavailable = (event) => {
                console.log('MediaRecorder data available.');
                audioChunks.current.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                console.log('MediaRecorder stopped.');
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                audioChunks.current = [];

                // Send audioBlob to OpenAI for STT processing
                await sendToOpenAI(audioBlob);
            };

            mediaRecorder.onerror = (error) => {
                console.error('MediaRecorder error:', error);
            };

            // Start silence detection
            startSilenceDetection();

        } catch (error) {
            console.error('Error during start listening:', error);
            setMicrophoneAccessError(true);
        }
    };


    // Function to start silence detection
    const startSilenceDetection = () => {
        if (!analyserRef.current || !dataArrayRef.current) return;

        const checkSilence = () => {
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            // Calculate the amplitude of the sound
            let sum = 0;
            for (let i = 0; i < dataArrayRef.current.length; i++) {
                sum += Math.abs(dataArrayRef.current[i] - 128);
            }
            const amplitude = sum / dataArrayRef.current.length;

            if (amplitude < silenceThreshold) {
                // Detected silence
                if (!silenceTimer) {
                    setSilenceTimer(
                        setTimeout(() => {
                            console.log('Silence detected for 5 seconds, stopping recording.');
                            handleStop();
                            setShowRecordModal(false);
                            // Optionally, show a message to the user about no voice input
                        }, silenceTimeout)
                    );
                }
            } else {
                // Detected sound, clear the silence timer
                if (silenceTimer) {
                    clearTimeout(silenceTimer);
                    setSilenceTimer(null);
                }
            }

            // Continue checking for silence
            requestAnimationFrame(checkSilence);
        };

        checkSilence();
    };

    // Cleanup function for silence detection
    useEffect(() => {
        return () => {
            if (silenceTimer) {
                clearTimeout(silenceTimer);
            }
        };
    }, [silenceTimer]);





    const handleMicrophoneClick = async () => {
        await startMicrophoneSetup();
        startListening(); // Use the startListening function here
    };


    // Cleanup function to stop all media streams and reset recorder
    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current) {
                console.log('Stopping MediaRecorder.');
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
                mediaRecorderRef.current = null;
            }
            if (microphoneRef.current) {
                console.log('Stopping microphone stream.');
                microphoneRef.current.getTracks().forEach(track => track.stop());
                microphoneRef.current = null;
            }
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                console.log('Closing AudioContext.');
                audioContextRef.current.close().then(() => {
                    audioContextRef.current = null;
                });
            }
        };
    }, []);



    useEffect(() => {
        // Increment idle time only if showCallModal is not active
        if (!showCallModal) {
            const interval = setInterval(() => {
                setIdleTime((prevIdleTime) => prevIdleTime + 1);
            }, 1000); // Increment idle time every second

            // Reload the page if idle time exceeds 400 seconds
            if (idleTime >= 200) {
                window.location.reload(); // Reload the page
            }

            // Add event listeners to reset idle timer on user activity
            window.addEventListener('mousemove', resetIdleTimer);
            window.addEventListener('keypress', resetIdleTimer);
            window.addEventListener('touchstart', resetIdleTimer);
            window.addEventListener('click', resetIdleTimer);

            return () => {
                clearInterval(interval);
                window.removeEventListener('mousemove', resetIdleTimer);
                window.removeEventListener('keypress', resetIdleTimer);
                window.removeEventListener('touchstart', resetIdleTimer);
                window.removeEventListener('click', resetIdleTimer);
            };
        }
    }, [idleTime, resetIdleTimer, showCallModal]);




    const toggleCallModal = () => {
        console.log("Toggling Modal: Current State:", showCallModal);

        // Play the audio
        doneSound.play().catch(err => console.error('Error playing audio:', err));

        // Check if the modal is being closed
        if (showCallModal) {
            // If the modal is currently open, and this action is closing it
            doneSound.onended = () => {
                window.location.reload(); // Reload the page after the sound has played
            };
        } else {
            // If the modal is being opened, simply toggle it without reloading
            setShowCallModal(prevState => !prevState);
            console.log("Toggled Modal: New State:", !showCallModal); // Log the new state after toggling
            setTimer(300); // Reset the timer to 5 minutes when the modal opens
        }
    };

    // Countdown effect
    useEffect(() => {
        let countdownInterval;
        if (showCallModal && timer > 0) {
            countdownInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1); // Decrease the timer every second
            }, 1000);
        } else if (timer === 0) {
            // Close modal when timer reaches 0
            setShowCallModal(false);
            window.location.reload(); // Reload page after closing the modal
        }

        return () => clearInterval(countdownInterval); // Cleanup interval on unmount
    }, [showCallModal, timer]);




    const handleStop = () => {
        console.log('handleStop called. Initial states:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        // Cancel any ongoing API requests
        axios.CancelToken.source().cancel('Operation canceled by the user.');

        // Stop camera capture for both streams
        if (cameraStreamRef1.current) {
            const tracks = cameraStreamRef1.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef1.current = null;
            console.log('Camera stream 1 stopped.');
        } else {
            console.log('Camera stream 1 was not active.');
        }

        if (cameraStreamRef2.current) {
            const tracks = cameraStreamRef2.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef2.current = null;
            console.log('Camera stream 2 stopped.');
        } else {
            console.log('Camera stream 2 was not active.');
        }

        // Use refs to access video elements and stop them
        const videoRef1 = document.getElementById('cameraVideo1');
        const videoRef2 = document.getElementById('cameraVideo2');

        if (videoRef1 && videoRef1.srcObject) {
            const stream = videoRef1.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef1.srcObject = null;  // Clear the video source
            videoRef1.pause();  // Pause the video playback
            console.log('Video preview 1 stopped and source cleared.');
        } else {
            console.log('Video preview 1 was not active or has no stream.');
        }

        if (videoRef2 && videoRef2.srcObject) {
            const stream = videoRef2.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef2.srcObject = null;  // Clear the video source
            videoRef2.pause();  // Pause the video playback
            console.log('Video preview 2 stopped and source cleared.');
        } else {
            console.log('Video preview 2 was not active or has no stream.');
        }

        // Ensure the camera and microphone streams from Record.js are stopped
        if (microphoneRef.current) {
            microphoneRef.current.getTracks().forEach(track => track.stop());
            microphoneRef.current = null;
            console.log('Microphone stream stopped.');
        }

        // Stop microphone listening
        if (isListening) {
            setIsListening(false);
            console.log('Microphone listening stopped.');
        } else {
            console.log('Microphone was not listening.');
        }

        // Check and log TTS status
        console.log('Current TTS status before stopping audio:', ttsStatus);
        if (ttsStatus === 'speaking') {
            console.log('TTS is currently speaking. Preparing to stop.');
        } else {
            console.log('TTS is not speaking.');
        }

        // Stop audio playback
        if (audioRef.current) {
            audioRef.current.pause();  // Pause the audio
            audioRef.current.currentTime = 0;  // Reset the audio playback time to the beginning
            audioRef.current = null;  // Clear the audio reference
            console.log('Audio playback stopped.');

            // Explicitly set TTS status to "silence"
            setTtsStatus('silence');
            console.log('TTS status set to "silence".');
        } else {
            console.log('No audio was playing.');
        }

        // Reset any ongoing state or processes
        setLoading(false);
        setPeopleOptions([]);
        setShowModal(false);
        setShowRecordModal(false);
        setShowPeopleOptionsModal(false);
        setTranscription('')

        // Enable the microphone button after all processes have been stopped
        setIsMicDisabled(false);
        console.log('Microphone button enabled.');

        // Debugging the state after all actions
        console.log('Final states after handleStop execution:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        console.log('All processes stopped and microphone re-enabled.');
    };





    const handleStart = () => {
        // Close the start modal
        setShowStartModal(false);

        // Create and play the pre-recorded welcome message
        // audioRef.current = new Audio('/welcome.mp3');
        audioRef.current = new Audio('/sayname.mp3');
        // audioRef.current = new Audio('/say_your_name.mp3');
        audioRef.current.play();
    };


    // Function to handle text-to-speech
    const handleTextToSpeech = useCallback(async (text) => {
        try {
            // Stop and reset current audio if it's already playing
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                console.log('Existing audio stopped and reset.');
                audioRef.current = null;  // Clear previous reference
            }

            // Call API to get TTS audio
            const response = await axios.post(
                'https://api.openai.com/v1/audio/speech',
                {
                    model: 'tts-1',
                    input: text,
                    voice: 'nova',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                    responseType: 'blob',
                }
            );

            // Create new audio element from response
            const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
            const audio = new Audio(audioUrl);
            audioRef.current = audio;

            // Debugging: Check if audio element is created
            console.log('New audio element created:', audio);

            // Set TTS status to "speaking" when audio starts
            audio.onplay = () => {
                setTtsStatus('speaking');
                console.log('TTS started speaking.');
            };

            // Set TTS status to "silence" when audio ends
            audio.onended = () => {
                setTtsStatus('silence');
                audioRef.current = null;  // Clear the audio reference when finished
                console.log('TTS finished speaking and status set to "silence".');
            };

            // Play the audio
            audio.play().catch((err) => console.error('Error playing audio:', err));

            // Debugging: Confirm the play action is initiated
            console.log('Audio play action initiated.');
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    }, []);

    useEffect(() => {
        if (peopleOptions.length > 0) {
            setIsLoading(true); // Show loading effect
            setTimeout(() => {
                setIsLoading(false); // Hide loading effect after 1 second
                setShowPeopleOptionsModal(true); // Show the modal
            }, 2000); // Delay for 1 second (1000 milliseconds)
        } else {
            setShowPeopleOptionsModal(false);
        }
    }, [peopleOptions]);

    useEffect(() => {
        const startCameraStream = (ref, videoElementId) => {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    ref.current = stream;
                    const videoElement = document.getElementById(videoElementId);
                    if (videoElement) {
                        videoElement.srcObject = stream;
                        videoElement.play();
                    }
                })
                .catch((error) => {
                    console.error(`Error accessing the camera for ${videoElementId}:`, error);
                });
        };

        if (showPeopleOptionsModal) {
            startCameraStream(cameraStreamRef1, 'cameraVideo1');
        } else if (showModal) {
            startCameraStream(cameraStreamRef2, 'cameraVideo2');
        } else {
            handleCloseModal(cameraStreamRef1);
            handleCloseModal(cameraStreamRef2);
        }
    }, [showPeopleOptionsModal, showModal]);

    const handleCloseModal = (cameraRef) => {
        setShowModal(false);
        setShowPeopleOptionsModal(false);
        if (cameraRef.current) {
            const tracks = cameraRef.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraRef.current = null;
        }
    };

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);




    const handleSendMessage = useCallback(async (text = input) => {
        if (!text.trim()) return;

        const userMessage = { role: 'user', content: text };
        const updatedMessages = [...messages, userMessage];
        console.log('updatedMessages===>', updatedMessages);
        console.log(' updatedMessages', JSON.stringify(updatedMessages));

        setMessages(updatedMessages);
        setInput('');
        setIsMicDisabled(true);  // Disable the microphone button

        try {
            // Get only the last message from the user
            const lastUserMessage = updatedMessages[updatedMessages.length - 1];

            console.log('lastUserMessage', JSON.stringify(lastUserMessage));

            if (!confirmedUserName) {
                // Logic when confirmedUserName is false
                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-4o-mini',
                        messages: [
                            {
                                role: 'system',
                                content: `你是鴻翊國際公司的一名接待員。你的任務是詢問使用者的公司名稱及姓名，並在得到這些資訊後給予適當的回應。請確保在對話中有禮貌並引導使用者提供完整的資訊。當詢問時，請仔細區分提供的姓名是使用者本人的姓名，還是他想要拜訪的對象。如果使用者表示「我找某某先生」，這表明他在尋找某人，這並不是他本人的名字。請禮貌地請求澄清使用者的姓名。如果使用者沒有提供所有必要的訊息，請耐心地繼續詢問，直到獲得以下完整的資料：
                                1. 客戶的姓名
                                2. 客戶的公司名稱（如果未提供，則為可選項）
                                當你收集到所有資訊後，請用以下的 JSON 格式回應：`
                            },
                            ...updatedMessages,
                        ],

                        response_format: {
                            type: 'json_schema',
                            json_schema: {
                                name: 'user_info_response',
                                schema: {
                                    type: 'object',
                                    properties: {
                                        user_name: {
                                            type: 'string',
                                            description: 'The name of the user',
                                        },
                                        user_company: {
                                            type: 'string',
                                            description: 'The name of the user\'s company',
                                        },
                                        message: {
                                            type: 'string',
                                            description: 'The message to ask for missing information',
                                        },
                                        is_confirmed: {
                                            type: 'boolean',
                                            description: 'Whether the user has confirmed their name',
                                        },
                                    },
                                    required: ['user_name', 'user_company', 'message', 'is_confirmed'],
                                    additionalProperties: false,
                                },
                                strict: true,
                            },
                        },
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${config.openAiApiKey}`,
                        },
                    }
                );

                const structuredResponse = JSON.parse(response.data.choices[0].message.content);
                const { user_name, user_company, message: responseMessage, is_confirmed } = structuredResponse;

                let message;
                if (user_name && is_confirmed) {
                    // Set confirmed user name and user company in state
                    setUserProfile(prevProfile => ({
                        ...prevProfile,
                        user_name,
                        user_company: user_company || prevProfile.user_company,
                    }));
                    setConfirmedUserName(user_name);

                    // Prepare the appropriate message
                    message = user_company
                        ? `您好，${user_name}，來自 ${user_company}。很高興為您服務。請按麥克風幫您搜尋或按上面快捷鍵通話`
                        : `您好，${user_name}。很高興為您服務。請問您要找誰呢?請按麥克風幫您搜尋或按上面快捷鍵通話`;
                } else {
                    message = responseMessage;
                }

                setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: message }]);
                await handleTextToSpeech(message);

            } else {
                // Logic when confirmedUserName is true

                // Fetching the latest officeContacts and formatting them
                const formatOfficeContacts = (contacts) => {
                    return contacts.map(contact =>
                        `部門：${contact.department}，職稱：${contact.title}，中文名：${contact.chinese_name}，英文名：${contact.english_name}，部門代表：${contact.department_representative ? '是' : '否'}，電話：${contact.phone}`
                    ).join('，');
                };

                const formattedOfficeContacts = formatOfficeContacts(officeContacts);

                console.log('formattedOfficeContacts', formattedOfficeContacts)

                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-4o-mini',
                        messages: [
                            {
                                role: 'system',
                                content: `你是鴻翊國際公司的一名接待員。以下是辦公室同事的聯絡名單：${formattedOfficeContacts}。如果使用者查詢某個部門並有多位同事，請列出所有同事的中文名字及英文名字和Title，並詢問他們要聯絡哪一位。當顯示聯絡人時，請告知使用者聯絡人姓名,請勿說出電話號碼。如果有多於一位聯絡人，請只念出第一位的姓名，加上'等' 並說明總共有多少位同事。`,
                            },
                            lastUserMessage,
                        ],

                        response_format: {
                            type: 'json_schema',
                            json_schema: {
                                name: 'office_contact_response',
                                strict: true,
                                schema: {
                                    type: 'object',
                                    properties: {
                                        contacts: {
                                            type: 'array',
                                            items: {
                                                type: 'object',
                                                properties: {
                                                    chinese_name: { type: 'string' },
                                                    english_name: { type: 'string' },
                                                    department: { type: 'string' },
                                                    title: { type: 'string' },
                                                    phone: { type: 'string', "description": "phone number has to be full phone number with + initial" },
                                                    department_representative: { type: 'boolean' },
                                                },
                                                required: ['chinese_name', 'english_name', 'department', 'title', 'phone', 'department_representative'],
                                                additionalProperties: false,
                                            },
                                        },
                                        message: { type: 'string' },
                                    },
                                    required: ['contacts', 'message'],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${config.openAiApiKey}`,
                        },
                    }
                );

                const assistantMessageContent = response.data.choices[0].message.content;
                const structuredResponse = JSON.parse(assistantMessageContent);
                const { contacts, message } = structuredResponse;

                setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: message }]);

                console.log('contacts', contacts);
                setPeopleOptions(contacts); // Update with the contacts
                await handleTextToSpeech(message);
            }
        } catch (error) {
            console.error('獲取助手回應時出錯:', error.response ? error.response.data : error);
        } finally {
            setIsMicDisabled(false);  // Re-enable the microphone button after response or error
        }
    }, [input, messages, handleTextToSpeech, confirmedUserName, officeContacts]);






    // Log the updated user profile in useEffect
    useEffect(() => {
        if (userProfile.user_name) {
            console.log('Updated userProfile:', userProfile);
        }
    }, [userProfile]);


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const startListening = () => {
        console.log('Microphone button clicked!');
        setIsLoading(true); // Show the existing loading effect

        resetTranscriptionSent(); // Reset the transcription sent state


        // Open the record modal and start recording after a delay
        setTimeout(async () => {
            setIsLoading(false); // Hide loading effect after 1 second
            setShowRecordModal(true); // Show the Record modal

            // Start recording logic
            try {
                if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    console.log('AudioContext created successfully @ startlistening.');
                }

                // Ensure the AudioContext is in a running state
                if (audioContextRef.current.state === 'suspended') {
                    await audioContextRef.current.resume();
                    console.log('AudioContext resumed.');
                }

                // Request microphone access and start recording
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                console.log('Microphone access granted.');
                microphoneRef.current = stream;

                sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
                console.log('MediaStreamSource created successfully.');
                analyserRef.current = audioContextRef.current.createAnalyser();
                analyserRef.current.fftSize = 2048;
                sourceRef.current.connect(analyserRef.current);

                dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
                visualize();

                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                mediaRecorderRef.current = mediaRecorder;
                console.log('MediaRecorder initialized successfully.');
                mediaRecorder.start();
                console.log('MediaRecorder started.');

                mediaRecorder.ondataavailable = (event) => {
                    console.log('MediaRecorder data available.');
                    audioChunks.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    console.log('MediaRecorder stopped.');
                    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                    audioChunks.current = [];

                    // Send audioBlob to OpenAI for STT processing
                    await sendToOpenAI(audioBlob);
                };

                mediaRecorder.onerror = (error) => {
                    console.error('MediaRecorder error:', error);
                };
            } catch (error) {
                console.error('Error during start listening:', error);
                setMicrophoneAccessError(true);
            }
        }, 2000); // 1 second delay
    };




    useEffect(() => {
        if (transcription && !isTranscriptionSent) {
            console.log('*** AUTO　Current transcription:', transcription);
            handleSendMessage(transcription); // Automatically send the message when transcription is received
            setIsTranscriptionSent(true); // Mark transcription as sent
            // Close the record modal
            setShowRecordModal(false);
            resetTranscriptionSent(); // Reset the transcription sent state
        }
    }, [transcription, handleSendMessage, isTranscriptionSent]);



    const resetTranscriptionSent = () => {
        setTimeout(() => {
            setTranscription(''); // Clear the transcription
            setIsTranscriptionSent(false);
        }, 100); // Delay of 100ms to ensure transcription handling is complete
    };




    const reloadPage = () => {
        window.location.reload();  // Function to reload the page
    };

    const handlePersonClick = async (person) => {
        try {
            // Start loading before processing
            setIsLoading(true);


            // Stop any currently playing audio
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current = null;
            }

            // Play a sound when a person is clicked
            blipSound.play();

            // Wait for blipSound to finish playing before playing ringSound
            blipSound.onended = () => {
                ringSound.play();
            };


            // Capture the image using the webcam
            const videoElement = document.createElement('video');
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoElement.srcObject = stream;

            // Wait for the video element to be fully loaded and playing
            await new Promise((resolve, reject) => {
                videoElement.onloadedmetadata = () => {
                    videoElement.play();
                };

                videoElement.oncanplay = () => {
                    resolve();
                };

                videoElement.onerror = reject;
            });

            // Add a small delay to ensure the video is properly rendering the stream
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 100ms delay

            // Capture a screenshot
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert to data URL (image)
            const imageUrl = canvas.toDataURL('image/jpeg');

            // Stop the camera stream after capture
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());

            // Upload the image and get the imageUrl
            const uploadedImageUrl = await uploadFile(imageUrl);

            // Proceed only if the image was successfully uploaded
            if (!uploadedImageUrl) {
                console.error('Image upload failed.');
                return;
            }

            // Close the people options modal
            console.log('imageUrl', uploadedImageUrl);
            setShowPeopleOptionsModal(false);

            console.log('Selected Person:', person);

            // Generate a new room ID
            function generateRoomID() {
                const timestamp = Date.now(); // 13-digit timestamp
                const randomChars = Math.random().toString(36).substring(2, 10); // 8 random alphanumeric characters
                return `code=${timestamp}${randomChars}`;
            }

            const newRoomID = generateRoomID();
            console.log(newRoomID);
            setRoomID(newRoomID);

            // Send person data to the API endpoint with room URL and uploaded image URL
            const response = await axios.post(config.linenotify, {
                command: "line notify",
                person: {
                    english_name: person.english_name,
                    chinese_name: person.chinese_name,
                    phone: person.phone, // Include the phone number in the payload
                },
                room_url: `${config.videoChatBaseUrl}/call?${newRoomID}`, // Use the base URL from config
                imageUrl: uploadedImageUrl, // Include the uploaded image URL
                user_profile: {  // Include the user profile in the payload
                    user_name: userProfile.user_name,
                    user_company: userProfile.user_company
                }
            });

            console.log('API Response:', response.data); // Log the response data for debugging

            // Show the call modal after a successful response
            setShowCallModal(true);  // Show the modal with the iframe

        } catch (error) {
            console.error('Error during image capture, upload, or API call:', error);
        } finally {
            // Delay stopping loading for 3 seconds
            setTimeout(() => {
                setIsLoading(false);
            }, 5000); // 3000 milliseconds = 3 seconds
        }
    };

    const uploadFile = async (image) => {
        const eventId = 'ai-reception'; // Replace with your actual event ID
        // 動態組合上傳 API URL
        const url = `${config.mediaserver}/uploadPic/${eventId}`;

        const filename = new Date().toISOString() + '.jpg'; // Generates a unique filename for the image
        const blob = await fetch(image).then(res => res.blob()); // Converts the base64 image to a blob

        let formData = new FormData();
        formData.append('file', blob, filename); // Prepares the form data for the file upload

        setLoading(true); // Set loading state to true

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                throw new Error('檔案上傳失敗');
            }

            const data = response.data;
            console.log('檔案上傳成功:', data);

            // Return the uploaded image URL
            return data.imgUrl;

        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
            return null; // Return null if the upload fails
        } finally {
            setLoading(false); // Reset loading state
        }
    };


    useEffect(() => {
        const videoElement = document.querySelector('#startModalVideo'); // Select video element by id

        if (videoElement) {
            if (showStartModal) {
                videoElement.play(); // Play the video when the modal is visible
            } else {
                videoElement.pause(); // Pause the video when the modal is hidden
                videoElement.currentTime = 0; // Reset the video to the start
            }
        }
    }, [showStartModal]);


    // Ensure video is not playing when confirmedUserName is true
    useEffect(() => {
        if (confirmedUserName) {
            const videoElement = document.querySelector('video'); // Select the video element
            if (videoElement) {
                videoElement.pause(); // Pause the video
                videoElement.currentTime = 0; // Reset the video to the start
            }
        }
    }, [confirmedUserName]);

    // const handleMicrophonePress = async () => {
    //     await handleStop();
    //     startListening();
    // };

    return (
        <div className="container" style={{ width: '100%', height: '93%', maxWidth: '100%', margin: '0 auto', padding: '20px' }}>



            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(10, 10, 10, 0.95)', // Darker grey background with less transparency
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1100,
                        overflow: 'hidden', // Prevent any overflow issues
                    }}
                >
                    {/* White box container for the loading bars */}
                    <div style={{ textAlign: 'center' }}>
                        {/* Information text with upward movement */}
                        <p
                            style={{
                                color: '#fff',
                                marginBottom: '40px',
                                fontSize: '36px', // Double the size of text
                            }}
                        >
                            開啟麥克風中...見到通訊畫面才說話 <br />
                            請先不急著說話,等我一下喔!!
                        </p>
                        {/* Dynamic bars container */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center', // Center bars horizontally
                            gap: '10px', // Increased gap for larger effect
                        }}>
                            {/* Individual bars with animation */}
                            {[...Array(7)].map((_, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: '15px', // Increased width for larger bars
                                        height: '100px', // Increased height for larger effect
                                        backgroundColor: `hsl(${index * 50}, 70%, 50%)`, // Different color for each bar
                                        animation: `bounce 1.5s ease-in-out infinite`, // Longer duration for smoother animation
                                        animationDelay: `${index * 0.15}s`, // Stagger the animation start time
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            )}



            {showStartModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.95)', // 90% transparent white background
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                        overflow: 'hidden',
                    }}
                >

                    {/* Display Taiwan time and office hours message at the top */}
                    <div style={{
                        position: 'absolute',
                        top: '20px',
                        left: '50%', // Center horizontally
                        transform: 'translateX(-50%)', // Adjust to center
                        zIndex: 9999, // Ensure it's on top
                        textAlign: 'center', // Center align the text
                        fontWeight: 'bold', // Make the text bold
                        color: '#fff' // Default text color
                    }}>

                        {/* Display the Taiwan time */}
                        <div style={{
                            fontSize: '4rem', // Larger font for better readability
                            color: 'darkgrey', // Black text for the time
                            marginBottom: '5px' // Space between time and message
                        }}>
                            {taiwanTime}
                        </div>

                        {/* Display the openMessage with <br /> */}
                        <div style={{
                            color: isOfficeOpen ? 'green' : 'blue', // Conditional color for open or closed
                            fontSize: '1.8rem', // Font size for the message
                            fontWeight: 'bold', // Bold text for the message
                        }}
                            dangerouslySetInnerHTML={{ __html: openMessage }} // Use dangerouslySetInnerHTML to allow <br />
                        />
                    </div>


                    {/* Image for "No Sales" in the top-right corner */}
                    <img
                        src="/nosales.png"
                        alt="No Sales"
                        style={{
                            position: 'absolute',
                            top: '20px', // Distance from the top
                            right: '20px', // Distance from the right
                            width: '200px', // Adjust the width as needed
                            zIndex: 1100, // Ensure it's above other content
                        }}
                    />


                    <img
                        src="/delivery1.png"
                        alt="delivery"
                        style={{
                            position: 'absolute',
                            top: '290px', // Distance from the top
                            right: '20px', // Distance from the right
                            width: '200px', // Adjust the width as needed
                            zIndex: 1100, // Ensure it's above other content
                        }}
                    />

                    {/* <img
                        src="/large1.png"
                        alt="delivery"
                        style={{
                            position: 'absolute',
                            top: '510px', // Distance from the top
                            right: '20px', // Distance from the right
                            width: '200px', // Adjust the width as needed
                            zIndex: 1100, // Ensure it's above other content
                        }}
                    /> */}

                    {/* Background Video */}
                    {/* Background Video */}
                    <video
                        id="startModalVideo" // Add id to target this video
                        src="/idle3.webm"
                        autoPlay
                        loop
                        muted
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 'auto', // Maintain aspect ratio
                            height: 'auto', // Maintain aspect ratio
                            maxWidth: '140%', // Max width 140% of the screen width
                            maxHeight: '140%', // Max height 140% of the screen height
                            objectFit: 'contain', // Contain within the bounding box
                            zIndex: 1,
                            opacity: 0.85, // 90% transparency
                        }}
                    />

                    {/* Centered Button */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2, // Ensure it's on top of the video
                            backgroundColor: 'transparent',
                            transform: 'translateY(30%)', // Move 10% lower
                        }}
                    >


                        {isOfficeOpen && (
                            <>
                                <button
                                    className={`btn btn-microphone ${isListening ? 'listening' : ''}`}
                                    onClick={() => {
                                        handleStart();
                                        const videoElement = document.querySelector('video');
                                        if (videoElement) {
                                            videoElement.pause();
                                            videoElement.currentTime = 0;
                                        }
                                    }}
                                    style={{
                                        width: '440px', // 1.5 times larger than the original 160px
                                        height: '440px', // 1.5 times larger than the original 160px
                                        display: 'flex',
                                        flexDirection: 'column', // Arrange content in a column
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '30px',
                                        borderRadius: '50%',
                                        border: 'none',
                                        cursor: isMicDisabled ? 'not-allowed' : 'pointer',
                                        opacity: isMicDisabled ? 0.5 : 1,
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                    }}
                                    disabled={isMicDisabled}
                                >
                                    {/* <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <FaPlay size={30} />
                                    </div> */}
                                    <div style={{
                                        fontSize: '28px',
                                        fontWeight: 'normal',
                                        textAlign: 'center',
                                        marginTop: '4px' // Slight spacing between the two lines of text
                                    }}>
                                        AI 訪客服務系統
                                    </div>
                                    <div style={{
                                        fontSize: '64px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: '8px' // Spacing between icon and text
                                    }}>
                                        請按我通話
                                    </div>

                                </button>



                                {/* <h1
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly more opaque for readability
                                        color: '#333', // Darker text color for contrast
                                        padding: '30px 50px', // Extra padding for a banner-like effect
                                        borderRadius: '15px', // Slightly larger border-radius for a smooth effect
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Stronger shadow for depth
                                        textAlign: 'center',
                                        marginTop: '50px',
                                        marginBottom: '20px',
                                        fontSize: '4.5em', // Much larger font size for a banner style
                                        fontWeight: 'bold',
                                        letterSpacing: '1.5px', // Increased letter spacing for a bolder look
                                        lineHeight: '1.2', // Line height adjustment for readability
                                    }}
                                >
                                    AI 訪客服務系統
                                </h1> */}
                            </>
                        )}

                    </div>
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '20px', position: 'relative' }}>
                {/* Left half for user's name */}
                {confirmedUserName && (
                    <h2 style={{
                        position: 'absolute',  // Absolute positioning to keep it aligned in the left half
                        left: '10%',           // Align in the left half
                        fontSize: '1.6rem',    // Slightly larger font size for visibility
                        fontWeight: '500',     // Medium weight for a bit more emphasis
                        color: '#5A4A00',      // Darker yellow-brown text color for better contrast
                        background: 'linear-gradient(135deg, #FFE600, #FFDD44)', // Bright yellow gradient background
                        padding: '10px 20px',  // Slightly increased padding for comfort
                        borderRadius: '12px',  // More rounded corners for a friendlier look
                        border: '2px solid #FFC300', // Stronger border with a golden yellow
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for depth
                        textShadow: '1px 1px 4px rgba(255, 255, 255, 0.5)', // Light white shadow for text clarity
                        transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                    }}>
                        您好, {confirmedUserName}!
                    </h2>
                )}

                {/* Centered company logo */}
                <img src="/datavan_icon.jpg" alt="Datavan Icon" style={{ maxWidth: '180px', marginRight: '20px' }} />
            </div>


            {/* Reload Button with Absolute Position */}
            <button
                onClick={reloadPage}  // Add reload function
                style={{
                    position: 'absolute',
                    top: '20px',  // Position the button at the top
                    right: '20px',  // Position the button to the right
                    backgroundColor: 'darkgrey',  // Dark grey background
                    color: 'white',  // White icon
                    border: 'none',
                    borderRadius: '50%',  // Circular shape
                    padding: '20px',  // Increase padding for larger size
                    cursor: 'pointer',
                    fontSize: '2rem',  // Increase font size for icon size
                    width: '60px',  // Set fixed width to ensure circular shape
                    height: '60px',  // Set fixed height to ensure circular shape
                    display: 'flex',  // Center icon inside button
                    justifyContent: 'center',  // Center icon horizontally
                    alignItems: 'center',  // Center icon vertically
                    zIndex: 1000  // Ensure button is above other elements
                }}
            >
                <FaSyncAlt />  {/* Reload icon */}
            </button>

            <div className="responsive-container" style={{ display: 'flex', flexDirection: 'column', height: '700px', width: '100%' }}>




                {/* First Section - 50% */}
                <div style={{ flex: '0 0 50%', display: 'flex', justifyContent: 'space-between' }}>
                    {confirmedUserName ? (
                        <div style={{ width: '100%', height: '700px', backgroundColor: '#f0f0f0', padding: '20px' }}>
                            {/* If a department is selected, show the contact list for that department */}
                            {selectedDepartment ? (
                                <div>
                                    <button
                                        onClick={() => {
                                            mouseClickSound.play(); // Play the blip sound when the button is clicked
                                            handleReturnToMain(); // Call the existing handler
                                        }}
                                        className="btn btn-secondary"
                                        style={{
                                            fontSize: '1.5rem',
                                            padding: '15px 30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px', // Space between icon and text
                                            height: '70px', // Larger height for the button
                                        }}
                                    >
                                        <FaArrowLeft size={24} /> {/* Icon for return */}
                                        返回
                                    </button>

                                    <h4 style={{
                                        fontWeight: 'bold', // Make the text bold
                                        margin: '20px 0', // Add space at the top and bottom
                                    }}>{selectedDepartment}</h4>

                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '30px' }}>
                                            {officeContacts
                                                .filter(contact => contact.department === selectedDepartment)
                                                .sort((a, b) => b.department_representative - a.department_representative) // Sort representatives first
                                                .map((contact, index) => {
                                                    // console.log(contact.department_representative); // Log for debugging

                                                    return (
                                                        <button
                                                            key={index}
                                                            className="btn btn-success"
                                                            style={{
                                                                fontSize: '1.5rem',
                                                                padding: '20px',
                                                                height: '120px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                position: 'relative',
                                                            }}
                                                            onClick={() => {
                                                                mouseClickSound.play(); // Play blip sound
                                                                handlePersonClick(contact);
                                                            }} // Play sound and handle click
                                                        >
                                                            {/* Display contact's name and title */}
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                {contact.chinese_name} ({contact.english_name})
                                                            </span>
                                                            <p style={{ marginBottom: '0', fontSize: '1.2rem' }}>
                                                                {contact.title}
                                                            </p>

                                                            {/* Display department representative badge if applicable */}
                                                            {contact.department_representative && (
                                                                <span
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '-20px',
                                                                        right: '10px',
                                                                        backgroundColor: '#ff6347',
                                                                        color: '#fff',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        fontSize: '1.3rem',
                                                                        transform: 'translate(25%, -25%)',
                                                                    }}
                                                                >
                                                                    部門聯繫人
                                                                </span>
                                                            )}
                                                        </button>
                                                    );
                                                })}
                                        </div>

                                    </div>
                                </div>
                            ) : (
                                // If no department is selected, show the department list
                                <div>
                                    <h4 style={{
                                        fontWeight: 'bold', // Make the text bold
                                        margin: '20px 0', // Add space at the top and bottom
                                    }}>
                                        依部門尋找
                                    </h4>

                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
                                        {Array.from(new Set(officeContacts.map(contact => contact.department)))
                                            .sort((a, b) => {
                                                const indexA = badgeDepartments.indexOf(a);
                                                const indexB = badgeDepartments.indexOf(b);

                                                if (indexA === -1 && indexB === -1) return 0;
                                                if (indexA === -1) return 1;
                                                if (indexB === -1) return -1;
                                                return indexA - indexB;
                                            })
                                            .map((department, index) => {
                                                const departmentCount = officeContacts.filter(contact => contact.department === department).length;

                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => {
                                                            mouseClickSound.play(); // Play blip sound
                                                            handleDepartmentClick(department);
                                                        }}
                                                        className="btn btn-primary"
                                                        style={{
                                                            fontSize: '2rem',
                                                            padding: '20px',
                                                            height: '100px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        {/* Department name */}
                                                        <span>{department}</span>
                                                        {/* Count of people in the department */}
                                                        <span style={{ fontSize: '1.2rem', marginTop: '10px', fontWeight: 'bold' }}>{departmentCount} 人</span>

                                                        {/* Show badge if department is in badgeDepartments array */}
                                                        {badgeDepartments.includes(department) && (
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '-15px', // Move badge higher
                                                                    right: '-15px', // Move badge to the right
                                                                    backgroundColor: '#ff6347',
                                                                    color: '#fff',
                                                                    padding: '10px 15px', // Increase padding for a larger badge
                                                                    borderRadius: '10px', // Larger rounded corners
                                                                    fontSize: '1.5rem', // Larger text size
                                                                    fontWeight: 'bold',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for emphasis
                                                                }}
                                                            >
                                                                常用
                                                            </span>
                                                        )}
                                                    </button>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        // Before the username is confirmed, show badgeDepartments buttons
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', width: '100%' }}>

                            {badgeDepartments.map((department, index) => (
                                <button
                                    key={index}
                                    className="btn btn-primary"
                                    style={{
                                        fontSize: '2rem',
                                        padding: '20px',
                                        height: '100px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                    }}
                                    onClick={() => {
                                        mouseClickSound.play(); // Play blip sound

                                        // Find the first matching person in the selected department
                                        const firstPersonInDepartment = officeContacts.find(contact => contact.department === department);

                                        // If a person is found, handle their selection
                                        if (firstPersonInDepartment) {
                                            handlePersonClick(firstPersonInDepartment); // Call the function to handle the person click
                                        } else {
                                            console.log(`No contacts found for department: ${department}`);
                                        }
                                    }}
                                >
                                    {department}
                                    {/* Show badge if department is in badgeDepartments array */}
                                    {badgeDepartments.includes(department) && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-15px', // Move badge higher
                                                right: '-15px', // Move badge to the right
                                                backgroundColor: '#ff6347',
                                                color: '#fff',
                                                padding: '10px 15px', // Increase padding for a larger badge
                                                borderRadius: '10px', // Larger rounded corners
                                                fontSize: '1.5rem', // Larger text size
                                                fontWeight: 'bold',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for emphasis
                                            }}
                                        >
                                            快捷
                                        </span>
                                    )}
                                </button>
                            ))}

                            {/* Video playing in the background */}
                            <div style={{
                                position: 'relative',
                                top: '0',
                                left: '100%',
                                width: '100%',
                                height: '100%',
                                zIndex: '-1', // Place video behind the buttons
                                overflow: 'hidden',
                            }}>
                                <video
                                    src="/idle3.webm"
                                    autoPlay
                                    loop
                                    muted
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover', // Ensure the video covers the area while maintaining aspect ratio
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>



                {/* Second Section - 20% */}
                <div style={{ flex: '0 0 25%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                    <button
                        className={`btn btn-microphone ${isListening ? 'listening' : ''}`}
                        onMouseDown={handleMicrophoneClick}
                        onTouchStart={handleMicrophoneClick}
                        style={{
                            width: '180px',
                            height: '180px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: isMicDisabled ? 'not-allowed' : 'pointer',
                            opacity: isMicDisabled ? 0.5 : 1,
                        }}
                        disabled={isMicDisabled}
                    >
                        <div style={{ ...highlightStyles, top: '0', left: '-120px' }}>
                            <div style={tooltipStyles1}>
                                {confirmedUserName
                                    ? '可按麥克風詢問要找的人'
                                    : '按麥克風告知您的名字及公司'}
                                <div style={arrowStyles1}></div>
                            </div>
                        </div>

                        {isListening ? <RiSpeakFill size={78} /> : <FaMicrophone size={78} />}
                    </button>
                </div>

                {/* Third Section - 30% */}
                <div className="chat-box mb-3" ref={chatBoxRef} style={{ overflowY: 'auto', flex: '0 0 90%', marginTop: '50px' }}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.role}`}>
                            <div className="bubble">
                                {msg.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>







            {/* Conditionally render the input section */}
            {showInputSection && (
                <div className="input-group" style={{ fontSize: '1.25rem', height: '6vh', width: '1000px' }}>
                    <input
                        type="text"
                        className="form-control"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="電腦打字輸入..."
                        style={{
                            fontSize: '3rem',
                            padding: '10px',
                            border: '2px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: 'none'
                        }}
                    />
                    <button
                        className="btn btn-primary"
                        onClick={() => handleSendMessage(input)}
                        style={{ fontSize: '2rem', padding: '10px 20px' }}
                    >
                        發送
                    </button>
                </div>
            )}

            {showRecordModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(20, 20, 20, 0.85)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                        padding: '20px',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            backgroundColor: '#ffffff',
                            borderRadius: '15px',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                            padding: '80px 80px 80px 80px', // Further increased padding: larger top padding for more space
                            textAlign: 'center',
                            width: '90%',
                            maxWidth: '600px',
                            transition: 'all 0.3s ease',
                        }}
                    >

                        {/* Large "X" button to close the modal */}
                        <button
                            onClick={() => {
                                stopSound.play().then(async () => {
                                    await handleStop();
                                    setTranscription('');
                                    setIsTranscriptionSent(false);
                                    setInput('');
                                    setPeopleOptions([]);
                                    setShowRecordModal(false);
                                    setShowPeopleOptionsModal(false);
                                    setShowModal(false);
                                }).catch(err => console.error('Error playing stop sound:', err));
                            }}
                            style={{
                                position: 'absolute',
                                top: '0px', // Ensure it's at the very top
                                right: '0px', // Ensure it's at the very right
                                background: 'none',
                                border: 'none',
                                fontSize: '6rem',
                                cursor: 'pointer',
                                color: '#555',
                                zIndex: 1100, // Make sure the "X" is above everything in the modal
                            }}
                            aria-label="Close"
                        >
                            &times;
                        </button>

                        {/* Rest of your modal content */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <canvas
                                ref={canvasRef}
                                style={{
                                    border: '2px solid green', // Changed border color to red
                                    borderRadius: '10px',
                                    width: '100%',
                                    maxWidth: '800px',
                                    maxHeight: '400px',
                                }}
                            />
                        </div>

                        <h3 style={{ marginBottom: '30px', fontSize: '1.8rem', color: '#333', fontWeight: 'bold', marginTop: '40px' }}>
                            請說話..說完話按停止鍵送出
                        </h3>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        handleStop();
                                    }).catch(err => console.error('Error playing stop sound:', err));
                                }}
                                style={{
                                    width: '160px',
                                    height: '160px',
                                    fontSize: '4rem',
                                    borderRadius: '50%',
                                    backgroundColor: '#000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                    animation: 'breathing 2s ease-in-out infinite',
                                }}
                            >
                                <div
                                    style={{
                                        width: '50%',
                                        height: '50%',
                                        backgroundColor: 'white',
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            )}




            {microphoneAccessError && (
                <div
                    style={{
                        position: 'fixed',
                        top: '20%',
                        left: '50%',
                        transform: 'translate(-50%, -20%)',
                        backgroundColor: '#ffdddd',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                        zIndex: 1001,
                        textAlign: 'center',
                    }}
                >
                    <p style={{ color: '#d8000c', fontSize: '1.2rem' }}>無法存取麥克風。請檢查您的瀏覽器權限設置。</p>
                    <button
                        onClick={onClose}
                        style={{
                            backgroundColor: '#d8000c',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            border: 'none',
                        }}
                    >
                        關閉
                    </button>
                </div>
            )}

            {showPeopleOptionsModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for responsiveness
                            maxWidth: '1000px',
                            height: '60vh', // Adjust height for responsiveness
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <h1>選擇聯絡人通話</h1>

                            {loading && (
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>檔案上傳中，請稍候...</p>
                                </div>
                            )}

                            {responseData && (
                                <div className="d-flex justify-content-center mt-4">
                                    <div>
                                        <h3>AI 分析結果</h3>
                                        <p>{responseData.someField}</p>
                                    </div>
                                </div>
                            )}

                            <video
                                id="cameraVideo1"
                                style={{
                                    width: '120px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    marginLeft: '20px',
                                }}
                                autoPlay
                            ></video>

                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef1);
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '4rem',
                                    cursor: 'pointer',
                                    color: 'black',
                                }}
                            >
                                &times;
                            </button>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                overflowY: 'auto',
                                marginTop: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px', // Adjusted gap for responsiveness
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {peopleOptions.map((person, index) => {
                                const colorClasses = ['btn-primary', 'btn-success'];
                                const randomColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];

                                return (
                                    <button
                                        key={index}
                                        className={`btn ${randomColorClass} text-white d-flex align-items-center justify-content-center`}
                                        onClick={() => handlePersonClick(person)}
                                        style={{
                                            width: '300px', // Adjusted width for responsiveness
                                            height: '80px', // Adjusted height for responsiveness
                                            fontSize: '1.5rem',
                                            margin: '10px',
                                            borderRadius: '8px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span>{person.chinese_name} ({person.english_name})</span>
                                        <span style={{ fontSize: '1rem', opacity: 0.8 }}>{person.title}</span>
                                    </button>
                                );
                            })}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    // Play the stop sound first
                                    stopSound.play()
                                        .then(async () => {
                                            // After the sound has finished playing, call handleStop and close the modal
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef1);
                                        })
                                        .catch(err => console.error('Error playing stop sound:', err));
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '20px 40px', // Adjusted padding for button
                                    border: 'none',
                                    borderRadius: '12px',
                                    cursor: 'pointer',
                                    fontSize: '1.5rem',
                                }}
                            >
                                關閉
                            </button>

                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%', // Adjust for full-screen modal on mobile
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for smaller screens
                            maxWidth: '900px',
                            height: 'auto', // Auto-adjust height
                            maxHeight: '80vh', // Constrain height for larger screens
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef2);
                                            window.location.reload();
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '5rem',
                                    cursor: 'pointer',
                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div style={{ flex: 1, overflowY: 'auto', marginTop: '10px' }}>
                            <Room match={{ params: { roomID } }} />
                        </div>
                        <div className="transcription-display" style={{ marginTop: '20px', color: '#333333' }}>
                            {transcription && <p style={{ color: 'white' }}>{transcription}</p>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    stopSound.play().then(() => {
                                        stopSound.onended = async () => {
                                            await handleStop();
                                            handleCloseModal(cameraStreamRef2);
                                            window.location.reload();
                                        };
                                    }).catch(err => console.error('Error playing audio:', err));
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showCallModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <div
                        style={{
                            width: '90%',
                            maxWidth: '1200px', // Adjusted for better view
                            height: 'auto',
                            maxHeight: '90vh', // Allow maximum height for larger screens
                            backgroundColor: '#fff',
                            borderRadius: '12px', // Smoother border radius
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Subtle box-shadow for depth
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <button
                            onClick={toggleCallModal}
                            style={{
                                position: 'absolute',
                                top: '20px', // Adjusted for better spacing
                                right: '20px',
                                background: 'none',
                                border: 'none',
                                fontSize: '6rem', // Adjusted size for better UI
                                cursor: 'pointer',
                                color: '#ffffff', // Default color is now white
                                transition: 'color 0.2s ease', // Smooth transition for color change
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.color = '#d3d3d3')} // Change color to light grey on hover
                            onMouseOut={(e) => (e.currentTarget.style.color = '#ffffff')} // Revert color to white on mouse out
                        >
                            &times;
                        </button>

                        {/* Embed the Whereby iframe */}
                        <div style={{ padding: '20px', textAlign: 'center' }}> {/* Padding around the iframe */}
                            <p style={{ color: '#4f4f4f' }}>剩餘時間: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}</p>

                            <iframe
                                src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                                allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                                style={{
                                    width: '100%', // Make iframe responsive and take full width
                                    height: '75vh', // Make the height 75% of the viewport height
                                    border: 'none', // Remove iframe border
                                    borderRadius: '8px', // Smoother iframe border radius
                                }}
                                frameBorder="0"
                                title="Video Call"
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Inject the keyframes for the flashing animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
            @keyframes flash {
                0 %, 100 % { opacity: 1; transform: scale(1); }
        50% {opacity: 0.8; transform: scale(1.05); }
    }
            `, styleSheet.cssRules.length);

export default Help;
